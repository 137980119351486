.campaign-action-btn {
    width:150px;
    word-wrap:break-word
}

.cancel-campaign-btn {
    float: right;
    border: 1px solid #ccc;
    border-radius: 20px;
    padding: 5px
}

.snapshot-template-container{
    height:300px;
    width:300px
}
.chart-full-width{
    width: 79% !important;
    height: 40%;
}

.link-button{
   color: #005974;
   background: none;
   border:none;
   padding: 0;
   font: inherit;
   cursor:pointer;
   word-break:break-word;
   text-decoration: underline;
   text-align: left;
}