.d-block{
    display: block;
}
.text-align-margin{
    text-align:center;
    margin-top: 30vh;
}

.text-align-right{
    text-align: right;
}

.text-align-center{
    text-align: center;
}

.text-trans-none{
    text-transform: none;
}

.pad-0{
    padding: 0;
}

.pad-10{
    padding: 10px;
}

.pad-20{
    padding: 20px;
}

.inline-blk{
    display: inline-block;
}

.flt-r{
    float: right;
}

.mar-t-10{
    margin-top: 10px;
}
.mar-b-10{
    margin-bottom: 10px;
}
.display-flex{
    display: flex;
}

.mar-t-15{
    margin-top: 15px;
}

.mar-r-10{
    margin-right: 10px;
}

.mar-l-10{
    margin-left: 10px;
}
.txt-24{
    font-size: 24px;
}

.no-data{
    color: #ccc;
    text-align: center
}


.table-filter{
    float: right;
     margin-top: 10px;
}

@media only screen and (max-width: 900px) {
    .table-filter{
        margin-top: 10px;
        float:left;
    }
  }


  .modal-body{
    height: 200px;
    width:500px;
  }

  .bill-card{
    min-height: 140px;
     min-width: 250px; 
    display: flex;
    flex-direction: column;
    align-items: center;
   flex-wrap: wrap;
    padding: 15px;
     box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
      border: 2px solid #ddd;
       margin: 10px;
       border-radius: 5px;
  }