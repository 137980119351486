.datepicker,
.datepicker .rdrStaticRangeLabel,
.datepicker .rdrMonthAndYearPickers select,
.datepicker .rdrMonth{
    font-family: "Source Sans Pro", sans-serif !important;
}

.datepicker .rdrStaticRange.rdrStaticRangeSelected {
    color:"#1976d2";
}

.datepicker .rdrDefinedRangesWrapper {
    width: 115px;
}

.datepicker .rdrMonth {
    padding-bottom: 0;
}

@media (max-width: 599px) {
    .datepicker {
        // top: -188px;
    }
}