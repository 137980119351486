.filter-container{
    background:#F2F4F5;
    padding: 15px;
}


.multi-select{
    &:hover{
        outline: none;
        border-radius:4px;
    }

}

.multi-select {
    svg{
        color: #005974;
    }
}

.autocomplete-class {
    svg{
        color: #005974;
    }
}


.filter-btn{
   margin: 47px 0 0 0!important;
}

.autocomplete-class{
    &:hover{
        outline: none;
        border: none;
    }
    &:focus{
        outline: none;
        border: none;
    }
}