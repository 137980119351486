$font-family: "Source Sans Pro", sans-serif !important;

:root {
  margin: 0;
  padding: 0;
  font-family: $font-family;
  line-height: 1.5;
  --color-green: #388557;
  --color-white: #ffffff;
  --color-yellow: #f0c01a;
  --color-black: #000000;
  --color-mildblue: #f4fbfe;
  --color-mildyellow: #fef9e8;
  --color-blue-primary: #005974;
  --color-lightblue: #e3f4fb;
  --color-lightred: #fce8ea;
  --color-blue: #1976d2;
  --color-aliceblue: #EAF2F5;
  --color-red: #be1317;
  --color-gray: #535353;
  --color-darkblue: #002a57;
  --color-lapisjewel: #14558f;
  --color-lightgreen: #0c8040;
  --color-lightshadegreen: #a3ffef;
  --color-lightgrey: #f2f2f2;
  --color-whitesmoke: #f5f5f5;
  --color-verylightshadegray: #fafafa;
  --color-waterysea: #88b8e7;
  --color-lightshadegray: #ddd;
  --color-chinesesilver: #ccc;
  --color-suvagray: #8a8a8a;
  --color-darkwashedazure: rgba(25, 118, 210, 0.5);
  --color-gainsboro: #D6DCDF;
  --color-grey: rgba(117, 117, 117, 0.2);
  --color-blackpearl: #1d2429;
  --color-blackrussian: #17181a;
  --color-darkblackpearl: #0a0c0d;
  --color-gradientblue: rgba(20, 85, 143, 0.5);
  --color-light: rgba(255, 255, 255, 0.5);
  --color-solitude: #f0f7f3;
  --color-tangaroa: #001e3e;
  --color-blacklight: rgba(0, 0, 0, 0.3);
  --color-lightBlue: #669bac;
}

/* font family */
@font-face {
  font-family: "Source Sans Pro";
  src: url("../assets/font/SourceSansPro-Bold.woff2") format("woff2"),
    url("../assets/font/SourceSansPro-Bold.woff") format("woff");
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: "Source Sans Pro";
  src: url("../assets/font/SourceSansPro-It.woff2") format("woff2"),
    url("../assets/font/SourceSansPro-It.woff") format("woff");
  font-weight: 400;
  font-style: italic;
}

@font-face {
  font-family: "Source Sans Pro";
  src: url("../assets/font/SourceSansPro-Regular.woff2") format("woff2"),
    url("../assets/font/SourceSansPro-Regular.woff") format("woff");
  font-weight: 400;
  font-style: normal;
}

.font-family {
  font-family: $font-family;
}

body,
html {
  font-family: $font-family;
}

body {
  margin: 0;
  font-family: $font-family;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}


// code {
//   font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
//     monospace;
// }

.decoded-message-wrapper {
  padding: 10px;
  border: 1px solid #ccc;
  word-wrap: break-word;
  max-height: 18rem;
  overflow-y: scroll;

}

.kpi-vertical-card {
  height: 50px;
  width: 100%;
  background: #fff;
  border: 1px solid #ddd;
  padding: 10px;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
  border-radius: 5px;
  margin-bottom: 10px;
}

.flex-container {
  display: flex;
  flex-wrap: nowrap;
}

.custom-cancel-button,
.custom-ok-button {
  border: 1px solid #002a57;    
  background-color: transparent;
  cursor: pointer;
  font-size: 16px;
  line-height: 1.75;
  min-width: 150px;
  padding: 5px 15px;
  border-radius: 4px;
  margin-left: 8px;
  font-family: $font-family;
}

.custom-ok-button {
  background-color: #002a57;
  color: #fff;
  font-family: $font-family;
  border: 1px solid #002a57;    

}

.swal2-container {
  z-index: 9999 !important;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type=number] {
  -moz-appearance: textfield;
}


.content-table {
  border-collapse: collapse;
  width: 100%;
  font-family: $font-family;
  font-size: 16px;
}

.content-td {
  border: 1px solid #bbb;
  padding: 10px;
}

.content-th {
  border: 1px solid #bbb;
  padding: 10px;
}


.txt-md {
  font-size: 18px;
}

.txt-heading {
  font-size: 16px;
}

.txt-xl {
  font-size: 32px;
}



.blk-center {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  flex-direction: column;
}

.txt-grey {
  color: #535353
}

.file-upload {
  border-bottom-right-radius: 0;
  border-top-right-radius: 0;
}

.create-using-account-radio.sms {
  label:nth-child(1) {
    border-radius: 50px;
    background: var(--color-darkblue) !important;
    color: var(--color-white) !important;
    width: 100%;

    span:nth-child(2) {
      color: var(--color-white);
    }
  }
}

.create-using-account-radio.email {
  label:nth-child(2) {
    border-radius: 50px;
    background: var(--color-darkblue) !important;
    color: var(--color-white) !important;
    width: 100%;

    span:nth-child(1) {
      color: var(--color-white);
    }
    
  }
}

.create-using-account-radio.Yes {
  label:nth-child(1) {
    border-radius: 50px;
    background: var(--color-darkblue) !important;
    color: var(--color-white) !important;
    width: 100%;

    span:nth-child(2) {
      color: var(--color-white);
    }

    
  }
}



.create-using-account-radio.No {
  label:nth-child(2) {
    border-radius: 50px;
    background: var(--color-darkblue) !important;
    color: var(--color-white) !important;
    width: 100%;

    span:nth-child(1) {
      color: var(--color-white);
    }
  }
}

.create-using-account-radio {

  label.MuiFormControlLabel-labelPlacementEnd {
    border: 1px solid #002a57;
    border-radius: 50px;
    width: 100%;

    height: 45px;
    margin : 0;
    margin-bottom: 8px;

    &:hover {
      background: var(--color-mildblue);
    }

   
  }

 

  .MuiFormControl-root {
    left: 10px;
  }
}


.form-ctrl-main-heading {
  color: var(--color-black);
  font-size: 16px;
  margin-bottom: 10px !important;
}

.blue-border {
  border: 3px solid #EDF6F5;
  margin: 20px 0;
}

.data-grid {
  margin: 12px 0
}

.page-heading {
  font-size: 24px;
  margin: 0
}

textarea {
  font-family: $font-family;
}

.content-wrapper {
  background: #fff;
}


@media (min-width:900px) and (max-width: 1024px) {
  .content-wrapper {
    width: 120%
  }
}


@media (min-width:800px) and (max-width: 900px) {
  .content-wrapper {
    width: 110%
  }
}

@media (min-width:1000px) and (max-width: 1200px) {
  .content-wrapper {
    width: 125%
  }
}

@media (min-width:1600px) and (max-width: 1800px) {
  .content-wrapper {
    margin-left: -3%
  }
}

@media (min-width:1800px) {
  .content-wrapper {
    margin-left: -5%
  }
}

.disable{
  label.MuiFormControlLabel-labelPlacementEnd {
    border: 1px solid grey;
    border-radius: 50px;
    width: 100%;
    color: grey; 
    opacity: 0.7;
    pointer-events: none;
    height: 45px;
    margin : 0;
    margin-bottom: 8px;
  }
  }

  .glossary{
    padding-left:20px
  }




