.green-wrapper {
  background-color: #388557;
  color: #fff;
}

.blue-wrapper {
    background: var(--color-white);
    height: 52px;
  }

.create-box-content{
  font-size: 16px;
  margin-top: 25px;
  margin-bottom: 5px;
  }
.wrapper-banner {
  background-color: transparent;
  border: none;
  padding-left: 2em;
  padding-right: 2em;
  margin-right: auto;
  margin-left: auto;
  max-width: 82rem;
  align-items: center;
  text-align: left;
}



.banner-logo-dark {
  filter: brightness(0) invert(1);
  height: 20px;
  width: 20px;
  margin-top: -3px;
}

.banner-text {
  margin: 0;
  padding: 5px 0 8.5px;
  margin-left: 2px;
  line-height: initial;
  font-weight: 350;
}

.img-margin {
  margin-bottom: 3.5rem;
}

.home-logo {
  padding: 0 0 5px 0;
  z-index: 9;

  a {
    text-decoration: none;
    display: flex;
    align-items: flex-start;

    span {
      font-size: 32px;
      color: #000000;
      font-weight: bold;
      margin-left: 16px;
      padding-top: 0.2rem;
    }
  }
  .link-underline {
    text-decoration: underline !important;
  }

  .main-logo {
    position: relative;
    padding-top: 3px;
  }

}


.contact-content {
  border: 1px dashed #002a57;
  padding: 20px;
  margin-top: 50px;
}

.margib-btn {
  margin: 0.5rem 0.8rem !important;
}

.content-height {
  height: 7.5rem;
}

.img-updown {
  position: relative;
  width: 12px;
  transition: all 1s ease;
}

.img-downup {
  position: relative;
  width: 12px;
  transition: all 1s ease;
}

.img-animation {
  transform: rotate(180deg);
}

.font-state-banner {
    font-size: 14px;
    font-weight: 350;
    a:hover {
      text-decoration: unset;
    }
  }
  

  .div-center {
    display: flex;
    align-items: center;
  }
  

.green-banner-contents {
  margin-top: 0.2em;
  margin-left: 0.5em;
  color: #fff;
}

.cursor-pointer {
    cursor: pointer;
  }

.m0 {
    margin: 0;
  }
  



.main-banner-wrapper{
    height: 100vh;
    background-image: url("../assets/banner-image1.png");
    background-repeat: no-repeat;
    background-size: cover;
    }

.top-paper-wrapper {
  margin-top: 50px;
  width:500px;
}


.banner-txt{
  padding: 5px 0;
  p {
    border-bottom: 1px solid #fbe28d;
    font-family: 'Source Sans Pro Light', sans-serif;
    font-size: 14.4px;
    font-weight: 400;
    line-height: 1.5;
    padding: 15px 0;
    margin: 0;
    color: #141414;
  }
  p:last-child {
    border-bottom: none;
  }
}


.lan-header{
  font-size: 18px;
}

.logo-text {
  margin: 5px 15px;
  color: #141414;
}

.green-banner-contents-wrapper {
  display: flex;
  margin: 10px 10px 10px 0;
}

.green-banner-heading {
  color: #fff;
  margin: 0;
  font-weight: 550;
}
.green-banner-contentsdiv1p2 {
  margin-top: 0.2em;
}
.green-banner-wrappper {
  display: flex;
  line-height: 1.4;
  margin-top: 5px;
  padding-bottom: 10px;
}

.mass-gov-banner-icon {
  margin-right: 5px;
  margin-top: 4px;
}