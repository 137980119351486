.chart-heading{
    font-size: 18px;
    display: inline-block;
    padding: 10px;
}

.round-kpi{
    background: #73C6B6;
    height: 250px;
    width: 250px;
    display: flex;
    border-radius: 50%;
    justify-content: center;
    align-items: center
}

.round-kpi-wrapper{
    padding: 10px;
     text-align: center;
      height: 300px;
      display:flex;
      align-content:center;
      justify-content:center
}


.chart-wrapper{
    text-align: center;
    border: 2px solid #ddd;
     border-radius: 5px;
      padding: 0 !important;
       box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
}



.dashboard-heading{
    padding: 10px 20px;
    background: #F2F4F5;
     margin: 20px 0;
}

.dashboard-data-block{
    // border: 1px solid #1976d2;
    //  min-height: 300px;
     padding: 20px;
     width: 100%;
     border-radius: 10px
}

.dashboard-chart-block{
    // border: 1px solid #1976d2;
    width: 100%;
    border-radius: 10px;
    display: flex;
    justify-content: center;
     align-items:center;
      height: 100%
}


