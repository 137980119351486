

.sideMenuLink {
  text-decoration: none;
  color: #002a57;
  width: 100%;
  display: block;
  padding-left: 10px;
}


.sideMenuItem:hover {
  color: #002a57;
  background-color: #EDF6F4;
}







